import React, { useState, useEffect, useRef } from 'react';
import Layoutas from '../../components/Layout';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
import Calculate from '../../util/calculate';

import { Tooltip } from 'antd';

import Sidetab from '../../components/Sidetab'
import Overview from './map_cct_contents/Overview'
import TargetSchoolView from './map_cct_contents/TargetSchoolView'
import MapAreaView from './map_cct_contents/MapAreaView'
import AcademyView from './map_cct_contents/AcademyView'
import { Modal, Divider } from 'antd';
import '../index.css';

import Star from '/images/university.svg';
import Dot from '/images/dot.svg';
import Dot2 from '/images/dot2.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TumbonAddr from '../../util/TumbonAddr'
import region from '../../util/region';
import api from '../../util/api';
import getProvinceName from '../../util/getProvinceName'

const MAP_TEXT = {
  "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป": 'พื้นที่สูง',
  "พื้นราบ": 'พื้นที่ราบ',
  "บนเกาะ": 'พื้นที่เกาะ',
  "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง": 'พื้นที่เขา',
  "โรงเรียนที่เป็นพื้นที่สูงและชายแดน": 'พื้นที่ติดชายแดน',
}

const MAP_SCHOOL_RESOURCE = {
  original: 'โรงเรียนต้นทาง',
  destination: 'โรงเรียนปลายทาง',
}

const FullMap = (props) => {
  const [initData, setInitData] = React.useState(null)
  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = React.useState(0);
  const [visible, setVisible] = useState(true);
  const [user_role, setUserRole] = useState([])
  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [modalUniversity, setModalUniversity] = useState({
    visible: false,
  });

  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    // footer: ''
    footer: <span>*สัดส่วนในแผนที่คำนวณจากนักเรียนที่<br />จะบรรจุไปยังโรงเรียนปลายทาง</span>
  })

  const [filter, setFilter] = React.useState({
    minView: "country",
    view: 'country',
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const updateFilter = (res) => {
    return setFilter({ ...res })
  }


  const handleChangeTab = (event, newValue, pretab) => {
    if (!([2, 3].includes(pretab) && [2, 3].includes(newValue))) {
      setFilter({
        ...filter, ...{
          view: filter.minView,
          region: "ทั้งหมด",
          province_name: filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
          city_name: null,
          tumbon_name: null,
        }
      })
    }
    setTabValue(newValue);
  };

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <Overview
        data={initData}
        filter={filter}
        setFilter={updateFilter} />
    },
    {
      title: 'ดูตามสถาบัน',
      content: <AcademyView
        data={initData}
        filter={filter}
        setFilter={updateFilter}
      />
    },
    {
      title: 'ดูตามโรงเรียนปลายทาง',
      content: <TargetSchoolView
        userRole={user_role}
        data={initData}
        filter={filter}
        setFilter={updateFilter}
      />
    },
    {
      title: 'ดูตามโรงเรียนต้นทาง',
      content: <MapAreaView
        data={initData}
        filter={filter}
        ShowModalSchool={(data) => {
          setModalSchool({
            visible: true,
            resource: 'original',
            ...data
          })
        }}
        setFilter={updateFilter}
      />
    }
  ]

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['krt'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['krt'] / result.reduce((acc, curent) => acc + curent.krt, 0)) * 100)
      }
      return element
    })

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.krt)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.krt)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.krt)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.krt)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const convertMapContent = (layer, data_school_des, data_school_ori, data_university) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { region: region.getRegion(item), province: item, total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    [data_school_des, data_school_ori, data_university].forEach((arr, idx) => {
      arr.forEach((item) => {
        let total = item.sum_krt
        let count = item.sum_krt
        if (layer === 'country') {
          let index = province.findIndex(ele => ele.province === item.province_name)
          if (index !== -1) {
            if (idx === 0) {
              !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
              !province[index]['krt'] ? province[index]['krt'] = count : province[index]['krt'] += count
              !province[index]['krt_sch_des'] ? province[index]['krt_sch_des'] = 1 : province[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !province[index]['krt_sch_ori'] ? province[index]['krt_sch_ori'] = 1 : province[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !province[index]['krt_university'] ? province[index]['krt_university'] = 1 : province[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'province') {
          let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
          if (index !== -1) {
            if (idx === 0) {
              !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
              !city[index]['krt'] ? city[index]['krt'] = count : city[index]['krt'] += count
              !city[index]['krt_sch_des'] ? city[index]['krt_sch_des'] = 1 : city[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !city[index]['krt_sch_ori'] ? city[index]['krt_sch_ori'] = 1 : city[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !city[index]['krt_university'] ? city[index]['krt_university'] = 1 : city[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'city') {
          let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
          if (index !== -1) {
            if (idx === 0) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
              !tumbon[index]['krt'] ? tumbon[index]['krt'] = count : tumbon[index]['krt'] += count
              !tumbon[index]['krt_sch_des'] ? tumbon[index]['krt_sch_des'] = 1 : tumbon[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !tumbon[index]['krt_sch_ori'] ? tumbon[index]['krt_sch_ori'] = 1 : tumbon[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !tumbon[index]['krt_university'] ? tumbon[index]['krt_university'] = 1 : tumbon[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'tumbon') {
          let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
          if (index !== -1) {
            if (idx === 0) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
              !tumbon[index]['krt'] ? tumbon[index]['krt'] = count : tumbon[index]['krt'] += count
              !tumbon[index]['krt_sch_des'] ? tumbon[index]['krt_sch_des'] = 1 : tumbon[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !tumbon[index]['krt_sch_ori'] ? tumbon[index]['krt_sch_ori'] = 1 : tumbon[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !tumbon[index]['krt_university'] ? tumbon[index]['krt_university'] = 1 : tumbon[index]['krt_university'] += 1
            }
          }
        }
      })
    })
    if (layer === 'country') {
      res = province
    } else if (layer === 'province') {
      res = city
    } else if (layer === 'city') {
      res = tumbon
    } else if (layer === 'tumbon') {
      res = tumbon
    }
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    // if (tabvalue == 0) {
    //   setTabValue(2)
    // }
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'province'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'city'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      if (property.type === 'school') {
        setModalSchool({
          visible: true,
          resource: 'destination',
          ...property
        })
      } else if (property.type === 'school_ori') {
        setModalSchool({
          visible: true,
          resource: 'original',
          ...property
        })
      } else if (property.type === 'university') {
        setModalUniversity({
          visible: true,
          ...property
        })
      }
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res_krt = await api.getInitData('krt')
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        setInitData({
          ...res_krt.deepdata.province[getProvinceName(pid)],
          school_ori2563: res_krt.deepdata.school_ori2563.filter(item => item.province_name === getProvinceName(pid)),
          school_ori2564: res_krt.deepdata.school_ori2564.filter(item => item.province_name === getProvinceName(pid)),
          school_ori2565: res_krt.deepdata.school_ori2565.filter(item => item.province_name === getProvinceName(pid)),
          school_ori2566: res_krt.deepdata.school_ori2566.filter(item => item.province_name === getProvinceName(pid)),
          school2563: res_krt.deepdata.school2563.filter(item => item.province_name === getProvinceName(pid)),
          school2564: res_krt.deepdata.school2564.filter(item => item.province_name === getProvinceName(pid)),
          school2565: res_krt.deepdata.school2565.filter(item => item.province_name === getProvinceName(pid)),
          school2566: res_krt.deepdata.school2566.filter(item => item.province_name === getProvinceName(pid)),
          university2563: res_krt.deepdata.university2563.filter(item => item.province_name === getProvinceName(pid)),
          university2564: res_krt.deepdata.university2564.filter(item => item.province_name === getProvinceName(pid)),
          university2565: res_krt.deepdata.university2565.filter(item => item.province_name === getProvinceName(pid)),
          university2566: res_krt.deepdata.university2566.filter(item => item.province_name === getProvinceName(pid)),
        })
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        setInitData({ ...res_krt.deepdata })
      }
    }
  }, [])


  // useEffect(async () => {
  //   let [cct_fund] = await Promise.all([api.getInitData('krt')])
  //   setInitData({
  //     ...cct_fund.deepdata
  //   })
  // }, [])

  useEffect(() => {
    if (initData) {
      let dataContextUniversity = initData['university' + filter.year]
      let dataContextSchool = initData['school' + filter.year]
      let dataContextSchoolOri = initData['school_ori' + filter.year]
      if (filter.region !== 'ทั้งหมด') {
        dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
        dataContextSchool = dataContextSchool.filter(item => item.region === filter.region)
        dataContextSchoolOri = dataContextSchoolOri.filter(item => item.region === filter.region)
      }
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, dataContextSchool, dataContextSchoolOri, dataContextUniversity))
      let data_school = [...new Set(dataContextSchool)]
      let data_school_ori = [...new Set(dataContextSchoolOri)]
      let data_university = [...new Set(dataContextUniversity)]
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_school = [...new Set(dataContextSchool.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
          data_school_ori = [...new Set(dataContextSchoolOri.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_school = [...new Set(dataContextSchool.filter(item => item.province_name === filter.province_name))]
          data_school_ori = [...new Set(dataContextSchoolOri.filter(item => item.province_name === filter.province_name))]
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name))]
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'university', main_layer: filter.view, layer: 'marker', data: (tabvalue === 1 ? data_university.map(item => ({ ...item, type: 'university' })) : []), icon: Star },
        { id: 'school', main_layer: filter.view, layer: 'marker', data: (tabvalue === 2 ? data_school.map(item => ({ ...item, type: 'school' })) : []), icon: Dot },
        { id: 'school_ori', main_layer: filter.view, layer: 'marker', data: (tabvalue === 3 ? data_school_ori.map(item => ({ ...item, type: 'school_ori' })) : []), icon: Dot2 },
      ])


      let data_legend = [
        { name: 'จำนวนนักเรียนที่ได้รับทุน', value: response.data.reduce((acc, cur) => acc + cur.krt, 0), unit: 'คน' },
        { name: 'จำนวนสถาบันที่เข้าร่วม', value: response.data.reduce((acc, cur) => acc + cur.krt_university, 0), unit: 'แห่ง' },
        { name: 'จำวนโรงเรียนต้นทาง', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_ori, 0), unit: 'แห่ง' },
        { name: 'จำนวนโรงเรียนปลายทาง', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_des, 0), unit: 'แห่ง' },
      ]
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      })
    }
  }, [filter, tabvalue, initData])


  return (
    <Layoutas Role={['partner', 'eef', 'partner_province']} isHideFooter>
      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='text-3xl'>โรงเรียน{modalSchool?.hostname} <span className='text-xl'> ({modalSchool?.resource && MAP_SCHOOL_RESOURCE[modalSchool.resource]})</span></div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalSchool?.tumbon} อำเภอ{modalSchool?.city} จังหวัด{modalSchool?.province}</div>
        <Divider style={{ margin: 8 }} />
        <div className='text-3xl m-2'>ได้รับทุนจากโครงการครูรัก(ษ์)ถิ่น {modalSchool?.sum_krt} คน</div>
        <Divider style={{ margin: 4 }} />

        <div className='grid grid-cols-12 gap-4 '>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลทรัพยากร</div>
                {!user_role.includes('partner') && <div className='text-lg '>ขนาดโรงเรียน :  {modalSchool?.school_size} </div>}
                <div className='text-lg '>ลักษณะภูมิประเทศ :  {MAP_TEXT[modalSchool?.geographic_location]} </div>
                <div className='text-lg '>ไฟฟ้า :  {modalSchool?.school_has_electricity ? 'โรงเรียนมีไฟฟ้าใช้' : 'โรงเรียนไม่มีไฟฟ้าใช้'}  </div>
                <div className='text-lg '>แหล่งน้ำ :  {modalSchool?.school_water_source} </div>

                <div className='text-xl text-green-500 mt-8'>ข้อมูลนักเรียน</div>
                <div className='text-lg '>จำนวนนักเรียนทั้งหมด :   {modalSchool?.student_class_k + modalSchool?.student_class_p + modalSchool?.student_class_mt + modalSchool?.student_class_mp} คน </div>
                <div className='text-lg '>อนุบาล :  {modalSchool?.student_class_k} คน</div>
                <div className='text-lg '>ประถมศึกษา : {modalSchool?.student_class_p}  คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนต้น :  {modalSchool?.student_class_mt} คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนปลาย :  {modalSchool?.student_class_mp} คน</div>

              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500'>ข้อมูลบุคลากร</div>
                <div className='text-lg '>จำนวนครูทั้งหมด :  {
                  modalSchool?.teacher_academic_noskill +
                  modalSchool?.teacher_academic_skilled +
                  modalSchool?.teacher_academic_skilledspecial +
                  modalSchool?.teacher_academic_specialization +
                  modalSchool?.teacher_academic_specialize +
                  modalSchool?.teacher_academic_other
                }</div>
                <div className='text-lg '>ชำนาญการพิเศษ : <></>  {modalSchool?.teacher_academic_noskill} คน</div>
                <div className='text-lg '>ชำนาญการ :  {modalSchool?.teacher_academic_skilled} คน</div>
                <div className='text-lg '>เชี่ยวชาญพิเศษ :  {modalSchool?.teacher_academic_skilledspecial} คน</div>
                <div className='text-lg '>เชี่ยวชาญ :  {modalSchool?.teacher_academic_specialization} คน</div>
                <div className='text-lg '>ไม่มีวิทยะฐานะ :  {modalSchool?.teacher_academic_specialize} คน</div>

              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        width={'600px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        CancelText={'ปิด'}
        visible={modalUniversity.visible}
        // onOk={handleOk}
        okButtonProps={{ style: { display: 'none' } }}
        centered={true}
        onCancel={() => {
          setModalUniversity({ visible: false })
        }}
      >
        <div className='text-3xl'>{modalUniversity?.uname} </div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalUniversity?.tumbon_name} อำเภอ{modalUniversity?.city_name} จังหวัด{modalUniversity?.province_name}</div>
        <Divider style={{ margin: 8 }} />
        <div className='text-2xl m-2'>ได้รับทุนจากโครงการครูรัก(ษ์)ถิ่น {modalUniversity?.sum_krt} คน</div>
        <Divider style={{ margin: 4 }} />
      </Modal>


      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>
                สัดส่วนนักเรียนที่จะบรรจุไปยังโรงเรียนปลายทาง &nbsp;
              </span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tabvalue !== 0 && <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='w-auto h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษา</span>
              <div class="flex flex-row">
                {tabvalue === 3 && <div class="flex flex-row text-center mr-2">
                  <img src={Dot2} style={{ width: '20px', height: '20px' }} />
                  <span className='center mx-1 mb-2'>โรงเรียนต้นทาง</span>
                </div>}
                {tabvalue === 2 && <div class="flex flex-row text-center mr-2">
                  <img src={Dot} style={{ width: '20px', height: '20px' }} />
                  <span className='center mx-1 mb-2'>โรงเรียนปลายทาง</span>
                </div>}
                {tabvalue === 1 && <div class="flex flex-row text-center ml-2">
                  <img src={Star} style={{ width: '20px', height: '20px' }} />
                  <span className='center mx-1 mb-2'>สถาบันการศึกษาที่เข้าร่วมโครงการ</span>
                </div>}

              </div>
            </div>
          </div>}
        </div>
        <Sidetab
          title="ทุนครูรัก(ษ์)ถิ่น"
          tab={initData ? tab_content : []}
          open={open}
          titleRef='*ทุนครูรัก(ษ์)ถิ่น ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2563 - 2566'
          subjectTitleRef='*ประมวลผล ณ เดือนเมษายน 2566'
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={(event, res) => handleChangeTab(event, res, tabvalue)}
        />
      </div>
    </Layoutas >
  )
}

export default FullMap
