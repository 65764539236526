import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Chart'
import imageMarker from '/images/marker.png';
import CheckbooxButton from '../../../components/CheckbooxButton'
import FilterKRT from './FilterKRT'
import { Button } from 'antd';
import imageSchoolL from '/images/krt-school-l.png';

let MAP_TEXT = {
  'province_name': 'จังหวัด',
  'city_name': 'อำเภอ',
  'school': 'โรงเรียน'
}

const CardItem = (props) => {
  return (<div
    onClick={() => props.onClickButton(props)}
    className='w-11/12 justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 mx-2 mb-4 bg-white hover:bg-gray-100'
  >
    {/* "inline-flex items-center bg-emerald-500 border-0 py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded text-base mt-4 md:mt-0 text-white" */}
    <div className='flex flex-row justify-between'>
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          <span className='text-md font-bold text-center'>{MAP_TEXT[props.type]}{props.key_group}</span>
          {/* <span className='text-md font-bold text-center'>ได้รับทุน</span> */}
          {/* <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{props.sum_target_group} ทุน</p> */}
        </div>
      </div>

      {/* <Button type="primary" shape="round" onClick={() => props.onClickButton(props)}>สำรวจข้อมูล</Button> */}

    </div>
    <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>
    <div className='flex flex-row justify-between '>
      {
        props.type === 'school' ?
          <>
            <div className='flex flex-row'>
              {/* <span className='font-bold text-left text-sm xl:text-md'>จำนวนนักเรียนได้รับทุน</span> */}
              <span className='text-sm font-bold text-left mr-2'>จำนวนนักเรียนได้รับทุน</span>
              <span className='text-lg font-bold text-left text-green-500'>  {props.sum_krt} คน</span>
            </div>
          </>
          :
          <>
            <div className='flex flex-col'>
              <span className='text-sm xl:text-md font-bold text-left'>จำนวนโรงเรียน</span>
              <span className='text-lg font-bold text-left text-green-500'>{props.sum_school} ร.ร.</span>
            </div>

            <div className='flex flex-col'>
              <span className='text-sm xl:text-md font-bold text-right'>จำนวนนักเรียนได้รับทุน</span>
              <span className='text-lg font-bold text-right text-blue-500'>{props.sum_krt} คน</span>
            </div>
          </>
      }

    </div>

  </div>)
}

const TitleTab = ({ data, filter, count }) => {
  let text = ''
  if (filter.view === 'country') {
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ใน {count.count_province} จังหวัด นักเรียนได้รับทุนจำนวน<span className='text-[#038967] text-2xl'>{count.krtsum}</span> คน จาก <span className='text-[#038967] text-2xl'>{count.krtschool}</span> โรงเรียน
      </p>
    )
  } else if (filter.view === 'province') {
    // 'จังหวัด' + filter.province_name
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ใน จังหวัด{filter.province_name} มีนักเรียนได้รับทุนจำนวน<span className='text-[#038967] text-2xl'>{count.krtsum}</span> คน จาก <span className='text-[#038967] text-2xl'>{count.krtschool}</span> โรงเรียน
      </p>
    )
  } else {
    {
      return (
        <p className='text-center text-sm xl:text-lg font-baijam'>
          ใน จังหวัด{filter.city_name} อำเภอ{filter.city_name} นักเรียนได้รับทุนจำนวน <span className='text-[#038967] text-2xl'>{count.krtsum}</span> คน จาก <span className='text-[#038967] text-2xl'>{count.krtschool}</span> โรงเรียน
        </p>
      )
    }
  }
}

const MapAreaView = (props) => {

  let { filter, setFilter } = props

  let {
    school_ori2563,
    school_ori2564,
    school_ori2565,
    school_ori2566,
    count_student63,
    count_student64,
    count_student65,
    count_student66,
  } = props.data


  const [datas, setDatas] = useState([]);

  const [data, setData] = useState({
    krtNorth: 0,
    schoolNorth: 0,
    krtCentral: 0,
    schoolCentral: 0,
    krtNortheast: 0,
    schoolNortheast: 0,
    krtSouth: 0,
    schoolSouth: 0,
    krtsum: 0,
    krtschool: 0,
    count_province: 0,
    count_city: 0,
    count_tumbon: 0,
    name: ''
  });

  useEffect(() => {
    let result = {}
    let _data = []
    let _dataForSum = []
    let key_group = 'province_name'
    let name = ''
    // school_ori2564
    if (filter.year === "2566") {
      _dataForSum = [...school_ori2566]
      _data = [...school_ori2566]
    } else if (filter.year === "2565") {
      _dataForSum = [...school_ori2565]
      _data = [...school_ori2565]
    } else if (filter.year === "2564") {
      _dataForSum = [...school_ori2564]
      _data = [...school_ori2564]
    } else {
      _dataForSum = [...school_ori2563]
      _data = [...school_ori2563]
    }

    if (filter.view === 'country') {
      key_group = 'province_name'
      _data = _data.map(x => ({ ...x, type: 'province_name' }))
    }

    if (filter.view === 'province') {
      key_group = 'city_name'
      name = 'จังหวัด' + filter.province_name
      _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
    }

    if (filter.view === 'city') {
      key_group = 'hostname'
      name = 'อำเภอ' + filter.city_name
      _data = _data
        .filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => ({ ...x, type: 'school' }))
    }
    _data.forEach(element => {
      if (!result[element[key_group]]) {
        result[element[key_group]] = {
          sum_school: 1,
          key_group: element[key_group],
          sum_krt: element.sum_krt,
          type: element.type
        }
        if (element.type === 'school') {
          result[element[key_group]]['data'] = element
        }
      } else {
        result[element[key_group]].sum_school += 1
        result[element[key_group]].sum_krt += element.sum_krt
      }
    });

    setDatas([...Object.values(result)])

    // province_name
    setData({
      count_province_north: [... (new Set(_dataForSum.filter(x => x.region === "ภาคเหนือ").map(x => x.province_name)))].length,
      count_province_central: [... (new Set(_dataForSum.filter(x => x.region === "ภาคกลาง").map(x => x.province_name)))].length,
      count_province_northeast: [... (new Set(_dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").map(x => x.province_name)))].length,
      count_province_south: [... (new Set(_dataForSum.filter(x => x.region === "ภาคใต้").map(x => x.province_name)))].length,

      count_province: [... (new Set(_dataForSum.map(x => x.province_name)))].length,
      count_city: [... (new Set(_dataForSum.filter(x => x.province_name === filter.province_name).map(x => x.city_name)))].length,
      count_tumbon: [... (new Set(_dataForSum.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => x.tumbon_name)))].length,


      krtNorth: _dataForSum.filter(x => x.region === "ภาคเหนือ").reduce((acc, obj) => obj.sum_krt + acc, 0),
      schoolNorth: _dataForSum.filter(x => x.region === "ภาคเหนือ").length,
      krtCentral: _dataForSum.filter(x => x.region === "ภาคกลาง").reduce((acc, obj) => obj.sum_krt + acc, 0),
      schoolCentral: _dataForSum.filter(x => x.region === "ภาคกลาง").length,
      krtNortheast: _dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").reduce((acc, obj) => obj.sum_krt + acc, 0),
      schoolNortheast: _dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
      krtSouth: _dataForSum.filter(x => x.region === "ภาคใต้").reduce((acc, obj) => obj.sum_krt + acc, 0),
      schoolSouth: _dataForSum.filter(x => x.region === "ภาคใต้").length,
      krtsum: [...Object.values(result)].reduce((acc, obj) => obj.sum_krt + acc, 0),
      krtschool: [...Object.values(result)].reduce((acc, cur) => acc + cur.sum_school, 0),
      name
    })

  }, [filter])


  return (
    <div>

      <p className='text-center text-base lg:text-lg text-[#038967] mb-1'> เลือกดูข้อมูลโรงเรียนต้นทางโครงการทุนครูรัก(ษ์)ถิ่น</p>
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
            <div className=' flex flex-col' >
              <h5 className="text-sm m-0 ml-[9px]" >ปีการศึกษา: </h5>
              <div className='flex flex-row' >
                <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
                  filter.year = '2566'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
                  filter.year = '2565'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                  filter.year = '2564'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                  filter.year = '2563'
                  setFilter({ ...filter })
                }} />
              </div>
            </div>
            < FilterKRT
              data={filter}
              onChange={(res) => {
                setFilter({ ...filter, ...res })
              }}
            />
          </div>

        </div>

      </div>
      {/* 
      <p className='text-center text-base lg:text-lg'>
        ใน {data.count_province} จังหวัด นักเรียนได้รับทุนจำนวน<span className='text-[#038967] text-2xl'>{filter.year == '2563' ? count_student63 : count_student64}</span> คน จาก <span className='text-[#038967] text-2xl'>{filter.year == '2563' ? school2564.length : school2563.length}</span> โรงเรียน
      </p> */}

      <TitleTab
        data={props.data}
        filter={filter}
        count={{
          count_province: data.count_province,
          count_city: data.count_city,
          count_tumbon: data.count_tumbon,
          count_vecschool: data.vecschool,
          krtsum: data.krtsum,
          krtschool: data.krtschool
        }} />

      <div className="flex flex-col md:flex-row xl:mx-12">
        <div className='w-full md:w-96 h-min '>
          {filter.view === 'country' ?
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.count_province_north}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนโรงเรียน  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.schoolNorth}</span>  ร.ร.</div>
              <div className="ml-6">จำนวนนักเรียนได้รับทุน  <span className='text-sm xl:text-md font-bold text-center text-green-600'>{data.krtNorth}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคตะวันออกเฉียงเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.count_province_northeast}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนโรงเรียน  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.schoolNortheast}</span>  ร.ร.</div>
              <div className="ml-6">จำนวนนักเรียนได้รับทุน  <span className='text-sm xl:text-md font-bold text-center text-green-600'>{data.krtNortheast}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคใต้</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.count_province_south}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนโรงเรียน  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.schoolSouth}</span>  ร.ร.</div>
              <div className="ml-6">จำนวนนักเรียนได้รับทุน  <span className='text-sm xl:text-md font-bold text-center text-green-600'>{data.krtSouth}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคกลาง</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.count_province_central}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนโรงเรียน  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.schoolCentral}</span>  ร.ร.</div>
              <div className="ml-6">จำนวนนักเรียนได้รับทุน  <span className='text-sm xl:text-md font-bold text-center text-green-600'>{data.krtCentral}</span>  ทุน</div>
            </div>
            :
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>{data.name}</div>
              </div>
              {filter.view === 'province' ? <div className="ml-6">จำนวนอำเภอ  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.count_city}</span> อำเภอ</div> : <></>}
              <div className="ml-6">จำนวนโรงเรียน  <span className='text-sm xl:text-md font-bold text-center text-blue-600'>{data.krtschool}</span>  โรงเรียน</div>
              <div className="ml-6">จำนวนนักเรียนได้รับทุน  <span className='text-sm xl:text-md font-bold text-center text-green-600'>{data.krtsum}</span>  ทุน</div>

            </div>
          }
        </div>
        {/* <div className='w-[350px] h-[500px] overflow-y-scroll'> */}
        <div className='w-full md:w-[400px] h-[500px] mt-2 md:mt-0 overflow-y-scroll'>
          {datas.map(x => (<CardItem {...x}
            onClickButton={(res) => {
              if (x.type === 'province_name') {
                setFilter({
                  ...filter,
                  ...{
                    view: 'province',
                    province_name: res.key_group
                  }
                })
              } else if (x.type === 'city_name') {
                setFilter({
                  ...filter,
                  ...{
                    view: 'city',
                    city_name: res.key_group
                  }
                })
              } else if (x.type === 'school') {
                props.ShowModalSchool({ ...x.data, ... { tumbon: x.data.tumbon_name, city: x.data.city_name } })
              }
            }}
          />))}
        </div>
      </div>
    </div >
  )
}

export default MapAreaView