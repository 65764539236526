import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Chart'
import FilterKRT from './FilterKRT'
import imageSchoolS from '/images/krt-school-s.png';
import imageSchoolL from '/images/krt-school-l.png';
import imageSchoolX from '/images/krt-school-x.png';
import imageSchoolXL from '/images/krt-school-xl.png';

import imageBorder from '/images/krt-border.png';
import imageGround from '/images/krt-ground.png';
import imageHigh from '/images/krt-high.png';
import imageHill from '/images/krt-hill.png';
import imageIsload from '/images/krt-isload.png';

import CheckbooxButton from '../../../components/CheckbooxButton'
import MapSvgRegion from '../../../components/MapSvgRegion';
import MapSvgNode from '../../../components/MapSvgNode';

let MAP_YEAR = {
  "2563": "63",
  "2564": "64",
  "2565": "65",
  "2566": "66",
  2566: "66",
  2565: "65",
  2564: "64",
  2563: "63",
}
const Overview = (props) => {

  let {
    count_student66,
    count_student65,
    count_student64,
    count_student63,
    university2566,
    university2565,
    university2564,
    university2563,
    school2566,
    school2565,
    school2564,
    school2563,
    school_ori2566,
    school_ori2565,
    school_ori2564,
    school_ori2563,
  } = props.data

  let { filter, setFilter } = props
  console.log("Overview props :", props)
  const [data, setData] = useState({
    targat: count_student63 + count_student64 + count_student65 + count_student66,
    school: props.data[`school${filter.year}`].length,
    academyInclude: 0,
    student: props.data[`count_student${MAP_YEAR[filter.year]}`],
    school_ori: school_ori2566.length,
    schoolS: school2566.filter(x => x.school_size === "ขนาดเล็ก").length,
    schoolL: school2566.filter(x => x.school_size === "ขนาดกลาง").length,
    schoolX: school2566.filter(x => x.school_size === "ขนาดใหญ่").length,
    schoolXL: school2566.filter(x => x.school_size === "ขนาดใหญ่พิเศษ").length,

    schoolGround: school2566.filter(x => x.geographic_location === "พื้นราบ").length,
    schoolIsland: school2566.filter(x => x.geographic_location === "บนเกาะ").length,
    schoolhigh: school2566.filter(x => x.geographic_location === "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป").length,
    schoolHill: school2566.filter(x => x.geographic_location === "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง").length,
    schoolBorder: school2566.filter(x => x.geographic_location === "โรงเรียนที่เป็นพื้นที่สูงและชายแดน").length,
    university: university2566.length,
    MapRegion: {
      north: school2566.filter(x => x.region === "ภาคเหนือ").length,
      central: school2566.filter(x => x.region === "ภาคกลาง").length,
      northeast: school2566.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
      south: school2566.filter(x => x.region === "ภาคใต้").length
    },
  });

  const [provinceSum, setProvinceSum] = useState([])
  // if (filter.view === 'city_name') {
  //   key_group = 'hostname'
  //   _data = _data
  //     .filter(x => x.province === filter.province_name && x.district === filter.city_name).map(x => ({ ...x, type: 'school' }))
  // }
  useEffect(async () => {
    let _schools = []
    let _schools_full = []
    let countStudent = 0
    if (filter.year == "2566") {
      _schools_full = [...school2566]
      _schools = [...school2566].filter(x => {
        if (filter.view === 'city') {
          return x.province_name === filter.province_name && x.city_name === filter.city_name
        } else if (filter.view === 'province') {
          return x.province_name === filter.province_name
        } else {
          return true
        }
      })
    } else if (filter.year == "2565") {
      _schools_full = [...school2565]
      _schools = [...school2565].filter(x => {
        if (filter.view === 'city') {
          return x.province_name === filter.province_name && x.city_name === filter.city_name
        } else if (filter.view === 'province') {
          return x.province_name === filter.province_name
        } else {
          return true
        }
      })
    } else if (filter.year == "2563") {
      _schools_full = [...school2563]
      _schools = [...school2563].filter(x => {
        if (filter.view === 'city') {
          return x.province_name === filter.province_name && x.city_name === filter.city_name
        } else if (filter.view === 'province') {
          return x.province_name === filter.province_name
        } else {
          return true
        }
      })
    } else {
      _schools_full = [...school2564]
      _schools = [...school2564].filter(x => {
        if (filter.view === 'city') {
          return x.province_name === filter.province_name && x.city_name === filter.city_name
        } else if (filter.view === 'province') {
          return x.province_name === filter.province_name
        } else {
          return true
        }
      })
    }
    _schools.forEach(element => {
      countStudent += element.sum_krt
    })

    // sum_krt
    // data['student'] = props.data[`count_student${MAP_YEAR[filter.year]}`]
    data['student'] = countStudent
    data['school'] = _schools.length
    data['schoolS'] = _schools.filter(x => x.school_size === "ขนาดเล็ก").length
    data['schoolL'] = _schools.filter(x => x.school_size === "ขนาดกลาง").length
    data['schoolX'] = _schools.filter(x => x.school_size === "ขนาดใหญ่").length
    data['schoolXL'] = _schools.filter(x => x.school_size === "ขนาดใหญ่พิเศษ").length
    data['schoolGround'] = _schools.filter(x => x.geographic_location === "พื้นราบ").length
    data['schoolIsland'] = _schools.filter(x => x.geographic_location === "บนเกาะ").length
    data['schoolhigh'] = _schools.filter(x => x.geographic_location === "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป").length
    data['schoolHill'] = _schools.filter(x => x.geographic_location === "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง").length
    data['schoolBorder'] = _schools.filter(x => x.geographic_location === "โรงเรียนที่เป็นพื้นที่สูงและชายแดน").length
    data['MapRegion'] = {
      north: _schools_full.filter(x => x.region === "ภาคเหนือ").length,
      central: _schools_full.filter(x => x.region === "ภาคกลาง").length,
      northeast: _schools_full.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
      south: _schools_full.filter(x => x.region === "ภาคใต้").length
    }
    setData({ ...data })

    const province = [...new Set(props.data['school' + filter.year].map(item => item.province_id))].map(item => { return { id: item } });
    props.data['school' + filter.year].forEach((item) => {
      let count = 1
      let index = province.findIndex(ele => ele.id === item.province_id)
      // if(Calculate.Percentile(90, props.data['school' + filter.year].map(item => item.krt)))
      !province[index]['count'] ? province[index]['count'] = count : province[index]['count'] += count
    })
    let sum = province.map(item => item.count).reduce((a, b) => a + b)
    province.map(item => {
      let avg = sum / province.length
      let color = 0
      if (item.count > avg * 1.5) { color = '#008442' }
      else if (item.count > avg) { color = '#88cd92' }
      else if (item.count > 0) { color = '#d7eedb' }
      else { color = color = '#ffffff' }
      item.color = color
      return item
    })
    setProvinceSum(province)


  }, [filter])

  return (
    <div>
      <p className='text-center text-base lg:text-lg text-[#038967] mb-1'> เลือกดูข้อมูลโรงเรียนปลายทางโครงการทุนครูรัก(ษ์)ถิ่น</p>
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
            <div className=' flex flex-col' >
              <h5 className="text-sm m-0 ml-[9px]" >ปีการศึกษา: </h5>
              <div className='flex flex-row' >
                <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
                  filter.year = '2566'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
                  filter.year = '2565'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                  filter.year = '2564'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                  filter.year = '2563'
                  setFilter({ ...filter })
                }} />
              </div>
            </div>
            < FilterKRT
              data={filter}
              onChange={(res) => {
                setFilter({ ...filter, ...res })
              }}
            />
          </div>
        </div>
      </div>
      <p className='text-center text-base lg:text-lg font-baijam'>
        โรงเรียนปลายทางโครงการครูรัก(ษ์)ถิ่นจำนวน <span className='text-[#038967] text-2xl'>{data.school}</span> โรงเรียน ที่อยู่ในพื้นที่ห่างไกล <br />
        ได้รับการพัฒนาคุณภาพเพื่อรองรับครูรุ่นใหม่ในอนาคต <span className='text-[#038967] text-2xl'>{data.student} </span> คน
      </p>
      {
        !props.userRole.includes('partner') &&
        <>
          <p className='text-xl mb-1 text-[#0E3165]'>
            โรงเรียนปลายทางแบ่งตามขนาดโรงเรียน
          </p>
          <div className='grid grid-cols-12 gap-2'>

            <div className='col-span-12 md:col-span-6 flex-col'>
              <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                <img className="flex self-center xl:mx-4 w-[72px]" src={imageSchoolS} alt="images" />
                <div className='flex flex-1 flex-col'>
                  <span className='text-xl font-bold text-center mb-0'>รร.ขนาดเล็ก</span>
                  <span className='text-sm font-bold text-center'>จำนวนโรงเรียน</span>
                  <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.schoolS} รร.</p>
                </div>
              </div>
            </div>

            <div className='col-span-12 md:col-span-6 flex-col'>
              <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                <img className="flex self-center xl:mx-4 w-[72px]" src={imageSchoolL} alt="images" />
                <div className='flex flex-1 flex-col'>
                  <span className='text-xl font-bold text-center mb-0'>รร.ขนาดกลาง</span>
                  <span className='text-sm font-bold text-center'>จำนวนโรงเรียน</span>
                  <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.schoolL} รร.</p>
                </div>
              </div>
            </div>

            <div className='col-span-12 md:col-span-6 flex-col'>
              <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                <img className="flex self-center xl:mx-4 w-[72px]" src={imageSchoolX} alt="images" />
                <div className='flex flex-1 flex-col'>
                  <span className='text-xl font-bold text-center mb-0'>รร.ขนาดใหญ่</span>
                  <span className='text-sm font-bold text-center'>จำนวนโรงเรียน</span>
                  <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.schoolX} รร.</p>
                </div>
              </div>
            </div>

            <div className='col-span-12 md:col-span-6 flex-col'>
              <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                <img className="flex self-center xl:mx-4 w-[72px]" src={imageSchoolXL} alt="images" />
                <div className='flex flex-1 flex-col'>
                  <span className='text-xl font-bold text-center mb-0'>รร.ขนาดใหญ่พิเศษ</span>
                  <span className='text-sm font-bold text-center'>จำนวนโรงเรียน</span>
                  <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.schoolXL} รร.</p>
                </div>
              </div>
            </div>

          </div>
        </>
      }

      <p className='text-xl mb-1 mt-5 text-[#0E3165]'>
        โรงเรียนปลายทางแบ่งตามภูมิประเทศที่ตั้งโรงเรียน
      </p>
      <div className='grid grid-cols-12 gap-2'>

        <div className='col-span-12 md:col-span-6 lg:col-span-4 flex-col px-1'>
          <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <span className='text-lg font-bold text-center mb-0'>พื้นที่ราบ</span>
            <div className='flex flex-row justify-evenly '>
              <img className="flex self-center" src={imageGround} alt="image for banner" width={'56px'} style={{}} />
              <div className='flex flex-col p-2'>
                <span className='text-sm font-bold'>จำนวนโรงเรียน</span>
                <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{data.schoolGround} รร.</p>
              </div>
            </div>
          </div>
        </div>


        <div className='col-span-12 md:col-span-6 lg:col-span-4 flex-col px-1'>
          <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <span className='text-lg font-bold text-center mb-0'>พื้นที่เขา</span>
            <div className='flex flex-row justify-evenly '>
              <img className="flex self-center" src={imageHill} alt="image for banner" width={'56px'} style={{}} />
              <div className='flex flex-col p-2'>
                <span className='text-sm font-bold'>จำนวนโรงเรียน</span>
                <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{data.schoolHill} รร.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 lg:col-span-4 flex-col px-1'>
          <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <span className='text-lg font-bold text-center mb-0'>พื้นที่สูง</span>
            <div className='flex flex-row justify-evenly '>
              <img className="flex self-center" src={imageHigh} alt="image for banner" width={'56px'} style={{}} />
              <div className='flex flex-col p-2'>
                <span className='text-sm font-bold'>จำนวนโรงเรียน</span>
                <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{data.schoolhigh} รร.</p>
              </div>
            </div>
          </div>
        </div>


        <div className='col-span-12 md:col-span-6 lg:col-span-4 lg:col-start-3 flex-col px-1'>
          <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <span className='text-lg font-bold text-center mb-0'>พื้นที่ติดชายแดน</span>
            <div className='flex flex-row justify-evenly '>
              <img className="flex self-center" src={imageBorder} alt="image for banner" width={'56px'} style={{}} />
              <div className='flex flex-col p-2'>
                <span className='text-sm font-bold'>จำนวนโรงเรียน</span>
                <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{data.schoolBorder} รร.</p>
              </div>
            </div>
          </div>
        </div>


        <div className='col-span-12 md:col-span-6 lg:col-span-4 lg:col-start-7  flex-col px-1'>
          <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <span className='text-lg font-bold text-center mb-0'>พื้นที่เกาะ</span>
            <div className='flex flex-row justify-evenly '>
              <img className="flex self-center" src={imageIsload} alt="image for banner" width={'56px'} style={{}} />
              <div className='flex flex-col p-2'>
                <span className='text-sm font-bold'>จำนวนโรงเรียน</span>
                <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{data.schoolIsland} รร.</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <p className='text-center text-xl mt-8  text-[#0E3165]'>
        แผนที่แสดงจำนวนโรงเรียนปลายทางแบ่งตามภูมิภาค/จังหวัด
      </p>

      <div className='grid grid-cols-12 gap-1'>
        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-col p-2'>
              <span className='text-xl font-bold text-center'>แบ่งตามภูมิภาค</span>
              <MapSvgRegion
                unit="ร.ร."
                data={data.MapRegion}
              />
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-col p-2'>
              <span className='text-xl font-bold text-center'>แบ่งตามจังหวัด</span>
              <MapSvgNode
                unit={"รร."}
                data={provinceSum}
              />
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default Overview
