import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Chart'
import imageStudentsKRT from '/images/students-krt.png';
import imageKRTStudentsCapital from '/images/vec-1.png';
import imageKRTSchool from '/images/cct-3.png';
import imageKRTAcademyJoin from '/images/krt-7.png';
import MapSvgRegion from '../../../components/MapSvgRegion';
import CheckbooxButton from '../../../components/CheckbooxButton'
const Overview = (props) => {
  let {
    count_student63,
    count_student64,
    count_student65,
    count_student66,
    university2566,
    university2565,
    university2564,
    university2563,
    school2563,
    school2564,
    school2565,
    school2566,
    school_ori2563,
    school_ori2564,
    school_ori2565,
    school_ori2566,
  } = props.data

  let { filter, setFilter } = props

  const [data, setData] = useState({
    targat: count_student63 + count_student64 + count_student65 + count_student66, 
    academyInclude: [...new Set([...university2563.map(item => item.uname),...university2564.map(item => item.uname), ...university2565.map(item => item.uname)], ...university2566.map(item => item.uname))].length,
    student: count_student66,
    school_ori: school_ori2566.length,
    university: university2566.length,
  });

  const [data_school_ori, setDataSchoolOri] = useState({
    north: school_ori2566.filter(x => x.region === "ภาคเหนือ").length,
    central: school_ori2566.filter(x => x.region === "ภาคกลาง").length,
    northeast: school_ori2566.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
    south: school_ori2566.filter(x => x.region === "ภาคใต้").length
  });

  const [data_school_final, setDataSchoolfinal] = useState({
    north: school2566.filter(x => x.region === "ภาคเหนือ").length,
    central: school2566.filter(x => x.region === "ภาคกลาง").length,
    northeast: school2566.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
    south: school2566.filter(x => x.region === "ภาคใต้").length
  });

  useEffect(() => {
    if (filter.year === "2566") {
      data['student'] = count_student66
      data['school_ori'] = school_ori2566.length
      data['school_final'] = school2566.length
      data['university'] = university2566.length
      data_school_ori['north'] = school_ori2566.filter(x => x.region === "ภาคเหนือ").length
      data_school_ori['central'] = school_ori2566.filter(x => x.region === "ภาคกลาง").length
      data_school_ori['northeast'] = school_ori2566.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_ori['south'] = school_ori2566.filter(x => x.region === "ภาคใต้").length
      data_school_final['north'] = school2566.filter(x => x.region === "ภาคเหนือ").length
      data_school_final['central'] = school2566.filter(x => x.region === "ภาคกลาง").length
      data_school_final['northeast'] = school2566.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_final['south'] = school2566.filter(x => x.region === "ภาคใต้").length
    } else if (filter.year === "2565") {
      data['student'] = count_student65
      data['school_ori'] = school_ori2565.length
      data['school_final'] = school2565.length
      data['university'] = university2565.length
      data_school_ori['north'] = school_ori2565.filter(x => x.region === "ภาคเหนือ").length
      data_school_ori['central'] = school_ori2565.filter(x => x.region === "ภาคกลาง").length
      data_school_ori['northeast'] = school_ori2565.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_ori['south'] = school_ori2565.filter(x => x.region === "ภาคใต้").length
      data_school_final['north'] = school2565.filter(x => x.region === "ภาคเหนือ").length
      data_school_final['central'] = school2565.filter(x => x.region === "ภาคกลาง").length
      data_school_final['northeast'] = school2565.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_final['south'] = school2565.filter(x => x.region === "ภาคใต้").length
    } else if (filter.year === "2564") {
      data['student'] = count_student64
      data['school_ori'] = school_ori2564.length
      data['school_final'] = school2564.length
      data['university'] = university2564.length
      data_school_ori['north'] = school_ori2564.filter(x => x.region === "ภาคเหนือ").length
      data_school_ori['central'] = school_ori2564.filter(x => x.region === "ภาคกลาง").length
      data_school_ori['northeast'] = school_ori2564.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_ori['south'] = school_ori2564.filter(x => x.region === "ภาคใต้").length
      data_school_final['north'] = school2564.filter(x => x.region === "ภาคเหนือ").length
      data_school_final['central'] = school2564.filter(x => x.region === "ภาคกลาง").length
      data_school_final['northeast'] = school2564.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_final['south'] = school2564.filter(x => x.region === "ภาคใต้").length
    } else {
      data['student'] = count_student63
      data['school_ori'] = school_ori2563.length
      data['school_final'] = school2563.length
      data['university'] = university2563.length
      data_school_ori['north'] = school_ori2563.filter(x => x.region === "ภาคเหนือ").length
      data_school_ori['central'] = school_ori2563.filter(x => x.region === "ภาคกลาง").length
      data_school_ori['northeast'] = school_ori2563.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_ori['south'] = school_ori2563.filter(x => x.region === "ภาคใต้").length
      data_school_final['north'] = school2563.filter(x => x.region === "ภาคเหนือ").length
      data_school_final['central'] = school2563.filter(x => x.region === "ภาคกลาง").length
      data_school_final['northeast'] = school2563.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length
      data_school_final['south'] = school2563.filter(x => x.region === "ภาคใต้").length
    }

    setData({ ...data })
    setDataSchoolOri({ ...data_school_ori })
    setDataSchoolfinal({ ...data_school_final })
  }, [filter])

  return (
    <div>
      <p className='text-center text-base lg:text-lg font-baijam'>
        {/* ตั้งแต่ปีการศึกษา 2563 จนถึงปัจจุบันทุนครูรัก(ษ์)ถิ่น <br /> */}
        ทุนครูรัก(ษ์)ถิ่นเริ่มช่วยเหลือตั้งแต่ปีการศึกษา 2563 จนถึง 2566<br />
        มีนักเรียนที่ได้รับทุน <span className='text-[#038967] text-2xl'>{data.targat}</span> คน ได้เข้ารับการศึกษาใน <span className='text-[#038967] text-2xl'>{data.academyInclude}</span>  สถาบัน
      </p>


      <div className='grid grid-cols-12 gap-4'>

        <div className='col-span-12 lg:col-span-12'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 bg-white'>
            <Chart
              containerProps={{ style: { height: "200px" } }}
              className='p-4'
              options={{
                type: 'areaspline',
                title: 'จำนวนนักเรียนที่ได้รับทุนตั้งแต่ปี 2563 ถึง 2566',
                categories: ['2563', '2564', '2565', '2566'],
                yAxis: {
                  title: {
                    text: 'นักเรียนที่ได้รับทุนครูรัก(ษ์)ถิ่น (คน)',
                  }
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                },
              }}
              data={
                [{
                  name: 'นร.ทุนครูรัก(ษ์)ถิ่น',
                  marker: {
                    symbol: 'square'
                  },
                  data: [count_student63, count_student64, count_student65, count_student66],
                  color: '#ffb280'
                }]
              } />
          </div>
        </div>
        <div className='col-span-12 lg:col-span-12'>
          <div className='w-[full] flex justify-center mt-6'>
            <div className='w-[400px] bg-white flex flex-row justify-center border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
              <div className="mr-2" style={{ marginTop: 8, pading: 0 }}>ปีการศึกษา</div>
              <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
                filter.year = '2566'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
                filter.year = '2565'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                filter.year = '2564'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                filter.year = '2563'
                setFilter({ ...filter })
              }} />
            </div>

          </div>
        </div>


        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageKRTStudentsCapital} alt="images" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>นักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.student}</p>
              <span className='text-md font-bold text-center'>คน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageKRTAcademyJoin} alt="images" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>สถาบันที่เข้าร่วม</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.university}</p>
              <span className='text-md font-bold text-center'>สถาบัน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageKRTSchool} alt="images" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>โรงเรียนต้นทาง</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.school_ori}</p>
              <span className='text-md font-bold text-center'>โรงเรียน</span>
            </div>
          </div>
        </div>


        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageKRTSchool} alt="images" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>โรงเรียนปลายทาง</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.school_final}</p>
              <span className='text-md font-bold text-center'>โรงเรียน</span>
            </div>
          </div>
        </div>
      </div>

      <p className='text-center text-[#038967] font-bold mb-6 text-md lg:text-lg mt-20'>แผนที่แสดงจำนวนโรงเรียนต้นทาง/ปลายทางแบ่งตามภูมภาค ปีการศึกษา {filter.year}</p>

      <div className='grid grid-cols-12 gap-1'>
        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-1 flex-col p-2'>
              <p className='text-md font-bold text-center mb-4'>แผนที่แสดงสัดส่วนโรงเรียน<span className='text-green-500 underline decoration-sky-500'>ต้นทาง</span>แบ่งตามภูมิภาค</p>
              <MapSvgRegion
                unit="ร.ร."
                data={data_school_ori}
              />
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-1 flex-col p-2'>
              <span className='text-md font-bold text-center mb-4'>แผนที่แสดงสัดส่วนโรงเรียน<span className='text-green-500 underline decoration-sky-500'>ปลายทาง</span>แบ่งตามภูมิภาค</span>
              <MapSvgRegion
                unit="ร.ร."
                data={data_school_final}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Overview
