import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import CheckbooxButton from '../../../components/CheckbooxButton'
import university from '../../../util/image'

const DivBlurBottom = styled.div`
  overflow-y: scroll;
  position: relative;
  &:after { 
    content: '';
    position: fixed;
    left: 0px;
    right: 0px;
    height: 5%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
`
const CardUniversity = (props) => {
  console.log(props)
  return (
    <div className='w-full flex-row bg-white rounded-2xl drop-shadow-md'>
      <div className='grid grid-cols-12 gap-4 p-2'>
        <div className='col-span-9 my-auto flex flex-row'>
          <div className='hidden xl:flex'>
            <img className="mx-4" src={university[props.uname]?.default ? university[props.uname]?.default : university['empty'].default} alt="image for banner" width={'30px'} style={{}} />
          </div>
          <div className='flex flex-col'>
            <div className='text-[#333] font-bold text-md'>{props.uname}</div>
            <div className='text-[#999] font-bold text-sm'>{'จ.' + props.province_name + ' อ.' + props.city_name + ' ต.' + props.tumbon_name}</div>
          </div>
        </div>
        <div className='col-span-3 flex-col text-center'>
          <div className='text-[#333] font-bold text-lg'>
            <span className='text-center text-[#038967] font-bold mb-0 text-xl lg:text-2xl'>{props.sum_krt}</span>
          </div>
          <div className='text-[#333] font-bold text-md'>ได้รับทุน (คน)</div>
        </div>
      </div>
    </div>
  )
}



const CardTap = ({ arr, title, tab, onClick, istab }) => {
  return (<a className='col-span-6 xl:col-span-3 flex-col text-inherit' onClick={() => onClick(title)}>
    <div className={`relative w-full rounded-xl overflow-hidden shadow-lg border-solid border-4 ${tab === title ? 'border-green-500' : 'border-gray-200'} bg-white`}>
      <div className='w-full flex flex-col rounded-lg p-2'>
        {/* {title}<br /> จำนวนที่รับทุน  {arr.reduce(function (acc, obj) { return acc + obj.sum_vec }, 0)} คน */}
        <div className={`text-sm font-bold`}>{title}  < br /></div>
        <div className='text-sm text-[#999]'>สถาบัน <span className='text-green-500 text-lg font-bold'>{arr.length}</span> แห่ง</div>
        <div className='text-sm text-[#999]'>ได้รับทุน <span className='text-green-500 text-lg font-bold'>{arr.reduce(function (acc, obj) { return acc + obj.sum_krt }, 0)}</span> คน</div>
      </div>
    </div>
  </a >)
}

const Overview = (props) => {
  let { filter, setFilter } = props

  let {
    university2566,
    university2565,
    university2564,
    university2563,
  } = props.data

  let datas = []
  if (filter.year == "2566") {
    datas = [...university2566]
  } else if (filter.year == "2565") {
    datas = [...university2565]
  } else if (filter.year == "2564") {
    datas = [...university2564]
  } else {
    datas = [...university2563]
  }
  let [regionTab, setRegionTab] = useState('ทั้งหมด')
  let [show, setShow] = useState(datas.sort((a, b) => b.sum_krt - a.sum_krt))

  let [region, setRegion] = useState('ภาคกลาง')
  let north = datas.filter(x => x.region === 'ภาคเหนือ')
  let central = datas.filter(x => x.region === 'ภาคกลาง')
  let northeast = datas.filter(x => x.region === 'ภาคตะวันออกเฉียงเหนือ')
  let south = datas.filter(x => x.region === 'ภาคใต้')

  useEffect(() => {
    filter['region'] = regionTab
    setShow(datas.sort((a, b) => b.sum_krt - a.sum_krt).filter(x => x.region === regionTab || regionTab === 'ทั้งหมด'))
    setFilter({ ...filter })
  }, [regionTab])

  useEffect(() => {
    setShow(datas.sort((a, b) => b.sum_krt - a.sum_krt).filter(x => x.region === regionTab || regionTab === 'ทั้งหมด'))
  }, [filter])
  
  return (
    <div>
      <div className='w-[full] flex justify-center '>
        <div className='w-[400px] flex flex-row  justify-center bg-white border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
          <div className="mr-2" style={{ marginTop: 8, pading: 0 }}>ปีการศึกษา</div>
          <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
            filter.year = '2566'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
            filter.year = '2565'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
            filter.year = '2564'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
            filter.year = '2563'
            setFilter({ ...filter })
          }} />

        </div>
      </div>

      <p className='text-center text-md lg:text-md xl:text-lg font-baijam'>
        ใน  <span className='text-[#038967] text-2xl'>{datas.length} </span>สถาบันที่เข้าร่วมโครงการ มีนักเรียนได้รับทุนจำนวน  <span className='text-[#038967] text-2xl'>{datas.reduce(function (acc, obj) { return acc + obj.sum_krt }, 0)} </span> คน ที่เข้ารับการศึกษา
      </p>

      <div className='grid grid-cols-12 gap-1'>
        <CardTap arr={north} title={'ภาคเหนือ'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={northeast} title={'ภาคตะวันออกเฉียงเหนือ'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={south} title={'ภาคใต้'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={central} title={'ภาคกลาง'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
      </div>

      <DivBlurBottom className='grid grid-cols-12 gap-4 mt-4 max-h-[45vh] overflow-y-scroll'>
        {
          show.map(x => (
            <div className='col-span-12 lg:col-span-12 flex-col'>
              <CardUniversity {...x} />
            </div>
          ))
        }
      </DivBlurBottom>



    </div >
  )
}

export default Overview
